import React, {useState, useEffect} from "react"
import { graphql } from "gatsby"
import Layout from "src/components/core/layout/layout"
import Card from "src/components/core/card"
import styled from "styled-components"
import BookPreview from "src/components/books/book-preview"
import Spacer from "src/components/core/spacer"
import PostPreview from "src/components/posts/post-preview"

export const query = graphql`
  query BooksQuery {
    prismicBookssidebar {
      data {
        text {
          html
        }
      }
    }

    allPrismicTag {
      nodes {
        id
        prismicId
        data {
          tag
        }
      }
    }
    allPrismicPost {
      nodes {
        uid
        data {
          title {
            text
          }
          tags {
            tag {
              document {
                prismicId
              }
            }
          }
        }
      }
    }

    allPrismicBook(sort: {fields: data___order, order: ASC}) {
      nodes {
        uid
        data {
          title {
            text
          }
          previewavatar {
            localFile{
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                    originalImg
                    originalName
                    presentationWidth
                    presentationHeight
                  }
                }
              }
          }

          previewcover {
              md {
                url
              }
              sm {
                url
              }
            }
          cover {
            localFile {
              childImageSharp {
                fluid {
                  base64
                  tracedSVG
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                  originalImg
                  originalName
                  presentationWidth
                  presentationHeight
                }
              }
            }
          }
          abstract {
            html
          }
        }
      }
    }
  }
`

const Books = ({ data }) => {
  console.log(data)
  const books = data.allPrismicBook.nodes
  const posts = data.allPrismicPost.nodes
  const tags = data.allPrismicTag.nodes
  const corbuloTag = tags.find(tag => tag.data.tag === "corbulo's daughter")
  let linksHTML
  if (corbuloTag) {
    const corbuloTagId = corbuloTag.prismicId
    const corbuloPosts = posts.filter(post =>
      post.data.tags.some(tagEl => {
        console.log(tagEl)
        return tagEl.tag && tagEl.tag.document[0].prismicId === corbuloTagId
      })
    )
    const corbuloHTML = `<h4>Corbulo's Daughter</h4>
      <ul>
        ${corbuloPosts.map(post => (
          <li key={post.uid}>
            <a href={"post/" + post.uid}>{post.data.title.text}</a>
          </li>
        ))}
      </ul>
      `
    linksHTML += corbuloHTML
  }
  const capraTag = tags.find(tag => tag.data.tag === "the prophet of pompeii")
  if (capraTag) {
    const capraTagId = capraTag.prismicId
    const capraPosts = posts.filter(post =>
      post.data.tags.some(
        tagEl => tagEl.tag && tagEl.tag.document[0].prismicId === capraTagId
      )
    )
    const capraHTML = `<h4>The Prophet of Pompeii</h4>
      <ul>
        ${capraPosts.map(post => (
          <li key={post.uid}>
            <a href={"post/" + post.uid}>{post.data.title.text}</a>
          </li>
        ))}
      </ul>
      `
    linksHTML += capraHTML
  }
  const frontinusTag = tags.find(
    tag => tag.data.tag === "gods of blood and water"
  )
  if (frontinusTag) {
    const frontinusTagId = frontinusTag.prismicId
    const frontinusPosts = posts.filter(post =>
      post.data.tags.some(
        tagEl => tagEl.tag && tagEl.tag.document[0].prismicId === frontinusTagId
      )
    )
    const frontinusHTML = `<h4>Gods of Blood and Water</h4>
      <ul>
        ${frontinusPosts.map(post => (
          <li key={post.uid}>
            <a href={"post/" + post.uid}>{post.data.title.text}</a>
          </li>
        ))}
      </ul>
      `
    linksHTML += frontinusHTML
  }

  const [loaded, setLoaded] = useState(false)
  useEffect(()=>setLoaded(true), [])
return !loaded ? <Layout></Layout> : (
    <Layout 
    title='The books in the Flavian trilogy'
    toptags={false} sidebar="booksSidebar">
      <Spacer />
      {books.map(book => {
        const uid = book.uid,
          title = book.data.title ? book.data.title.text : null,
          bookCover = book.data.cover && book.data.cover.localFile && book.data.cover.localFile.childImageSharp
            ? book.data.cover.localFile.childImageSharp.fluid
            : null,
          abstract = book.data.abstract ? book.data.abstract.html : null,
          previewcover = book.data.previewcover,
          previewavatar = book.data.previewavatar && book.data.previewavatar.localFile  && book.data.previewavatar.localFile.childImageSharp ? book.data.previewavatar.localFile.childImageSharp.fluid
            : null

        return (
          <PostPreview
            key={title}
            title={title}
            bookCover={bookCover}
            abstract={abstract}
            uid={"/book/" + uid}
            previewCover={previewcover}
            previewavatar={previewavatar}
          />
        )
      })}
    </Layout>
  )
}

export default Books
